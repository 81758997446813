










































import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Watch } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputText from '@/components/Controls/InputText.vue'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

type Apartment = {
  bedrooms: string | null;
  bathrooms: string | null;
  floorArea: number | null;
  secureCarParks: string | null;
  cityViews: string | null;
  waterViews: string | null;
  elevatedOutlook: string | null;
  pool: string | null;
  tennisCourt: string | null;
}

type ServerData = { [key: string]: any }

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputText,
    InputTextarea,
    Calendar
  }
})
export default class ResidentialBlockOfApartments extends Vue {
  formData () {
    const data: ServerData = {
      GeneralCondition: this.generalCondition,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      ApproximateBuildYear: this.approximateBuildYear,
      CarParks: this.carParks
    }

    data.Apartments = this.apartments.map(a => ({
      Bedrooms: a.bedrooms,
      Bathrooms: a.bathrooms,
      FloorArea: a.floorArea,
      SecureParkingSpaces: a.secureCarParks,
      CityViews: a.cityViews,
      RiverViews: a.waterViews,
      ElevatedOutlook: a.elevatedOutlook,
      Pool: a.pool,
      TennisCourt: a.tennisCourt
    }))

    return data
  }

  loadData (data: any) {
    this.generalCondition = data.GeneralCondition
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.approximateBuildYear = data.ApproximateBuildYear
    this.carParks = data.CarParks

    if (!data.Apartments) return

    data.Apartments.forEach((t: any) => {
      this.apartments.push({
        bedrooms: t.Bedrooms,
        bathrooms: t.Bathrooms,
        floorArea: t.FloorArea,
        secureCarParks: t.SecureParkingSpaces,
        cityViews: t.CityViews,
        waterViews: t.RiverViews,
        elevatedOutlook: t.ElevatedOutlook,
        pool: t.Pool,
        tennisCourt: t.TennisCourt
      })
    })

    this.numberOfApartments = data.Apartments.length
  }

  propertyType () {
    return 'ResidentialBlockOfApartments'
  }

  @Watch('numberOfApartments')
  apartmentsChanged (newVal: number, oldVal: number | null) {
    if (newVal < 0) return
    if (oldVal === null) oldVal = 0
    if (newVal === this.apartments.length) return

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        this.apartments.push(this.newApartment())
      }
    } else {
      this.apartments = this.apartments.slice(0, newVal)
    }
  }

  apartments: Apartment[] = []

  newApartment (): any {
    return {
      bedrooms: null,
      bathrooms: null,
      floorArea: null,
      secureCarParks: null,
      cityViews: null,
      waterViews: null,
      elevatedOutlook: null,
      pool: null,
      tennisCourt: null
    }
  }

  generalCondition: any = null
  approximateBuildYear: any = null
  floorArea: any = null
  carParks: any = null
  floorConstruction: any = null
  wallConstruction: any = null
  roofConstruction: any = null
  numberOfApartments: any = null
}
